/* eslint-disable no-duplicate-imports */
import { getPromotionOptionsForCartLineItems, PromotionOptionsForCartLineItems } from '@msdyn365-commerce-modules/retail-actions';
import { IActionContext } from '@msdyn365-commerce/core';
import { CartPromotionLine } from '@msdyn365-commerce/retail-proxy';

/**
 * IPromotionData entity interface.
 */
export interface IPromotionData {
    promotionOptions?: CartPromotionLine[];
}

/**
 * Calls the Retail API and returns a promotion data
 */
export async function getPromotionData(ctx: IActionContext): Promise<IPromotionData> {
    const promotionData: IPromotionData = {};

    await getPromotionOptionsForCartLineItems(new PromotionOptionsForCartLineItems(ctx.requestContext.apiSettings), ctx)
        .then(promotionOptions => {
            if (promotionOptions) {
                promotionData.promotionOptions = promotionOptions;
            }
        })
        .catch(error => {
            ctx.telemetry.exception(error);
            return {};
        });

    return promotionData;
}
